<template>
  <div class="relative" :class="heightStyles">
    <div class="rc-overlay" :class="width">
      <h2
        v-if="blockData.headingOne"
        :class="headingOneStyles"
        class="rc-heading-one uppercase font-featured text-white leading-none m-0"
      >
        {{ blockData.headingOne }}
      </h2>

      <h3
        v-if="blockData.headingTwo"
        :class="headingTwoStyles"
        class="rc-heading-two uppercase font-featured text-white leading-none m-0"
      >
        {{ blockData.headingTwo }}
      </h3>

      <div v-if="blockData.ctaText" class="inline-block">
        <div
          class="flex items-center gap-2 text-lg bg-gray-900 text-white px-5 py-4 uppercase font-featured font-bold"
        >
          <span>{{ blockData.ctaText }}</span>
          <ArrowRight class="h-4" />
        </div>
      </div>
    </div>

    <video
      v-if="video"
      :poster="image"
      class="object-cover h-full w-full bg-blend-multiply"
      playsinline
      autoplay
      loop
      muted
    >
      <source
        v-if="mobileVideo"
        :src="mobileVideo"
        type="video/mp4"
        media="(max-width: 640px)"
        width="640"
        height="640"
      />
      <source
        v-if="video"
        :src="video"
        type="video/mp4"
        media="(min-width: 641px)"
        width="1920"
        height="1080"
      />
    </video>

    <picture
      v-if="hasImage && !hasVideo"
      class="object-cover h-full w-full bg-blend-multiply"
    >
      <source
        v-if="mobileImage"
        :srcset="mobileImage"
        media="(max-width: 640px)"
        width="640"
        height="640"
      />
      <source
        v-if="image"
        :srcset="image"
        media="(min-width: 641px)"
        width="1920"
        height="1080"
      />
      <img
        :src="image"
        alt=""
        class="object-cover h-full w-full bg-blend-multiply"
      />
    </picture>
  </div>
</template>

<script>
import config from 'config';
import ArrowRight from '../../svg/ui/ArrowRight';

export default {
  name: 'HeroInner',
  props: {
    blockData: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      default: null
    }
  },
  components: { ArrowRight },
  computed: {
    image() {
      if (
        !this.blockData ||
        !this.blockData.image ||
        !this.blockData.image.length
      )
        return '';
      const image = this.blockData.image[0].url;
      return `${config.speedSizeUrl}/${image}`;
    },
    video() {
      if (
        !this.blockData ||
        !this.blockData.video ||
        !this.blockData.video.length
      )
        return '';
      const video = this.blockData.video[0].url;
      return `${config.speedSizeUrl}/${video}`;
    },
    mobileImage() {
      if (!this.blockData) return '';
      const image =
        this.blockData.mobileImage && this.blockData.mobileImage.length
          ? this.blockData.mobileImage[0].url
          : this.image;
      return image ? `${config.speedSizeUrl}/${image}` : '';
    },
    mobileVideo() {
      if (!this.blockData) return '';
      const video =
        this.blockData.mobileVideo && this.blockData.mobileVideo.length
          ? this.blockData.mobileVideo[0].url
          : this.video;
      return video ? `${config.speedSizeUrl}/${video}` : '';
    },
    heightStyles() {
      if (!this.blockData) return 'rc-height-standard';
      if (this.blockData.height === 'narrow') {
        return 'rc-height-narrow';
      }
      if (this.blockData.height === 'tall') {
        return 'rc-height-tall';
      }
      return 'rc-height-standard';
    },
    headingOneStyles() {
      const hasStyle = this.blockData && this.blockData.headingStyles.length;
      const isBold = this.blockData.headingStyles.find(
        (style) => style === 'headingOneBold'
      );
      return hasStyle && isBold ? 'font-bold' : 'font-thin';
    },
    headingTwoStyles() {
      const hasStyle = this.blockData && this.blockData.headingStyles.length;
      const isBold = this.blockData.headingStyles.find(
        (style) => style === 'headingTwoBold'
      );
      return hasStyle && isBold ? 'font-bold' : 'font-thin';
    },
    hasVideo() {
      return !!(this.video || this.mobileVideo);
    },
    hasImage() {
      return !!(this.image || this.mobileImage);
    }
  }
};
</script>

<style scoped>
/* Override the ayko styles */
#app a:not(.menu-link):not(.action):not(.link):hover span {
  color: #fff;
}
.rc-overlay {
  top: 70%;
  left: 50%;
  width: 90vw;
  font-size: 48px;
  text-align: left;
  position: absolute;
  transform: translate(-50%, -50%);
  font-family: univia-pro, Helvetica, Arial, sans-serif;
}
.rc-heading-one {
  font-size: 28px;
}
.rc-heading-two {
  font-size: 28px;
}
.rc-height-narrow,
.rc-height-standard,
.rc-height-tall {
  height: 500px;
}
@media (min-width: 768px) {
  .rc-overlay {
    top: 70%;
  }
  .rc-height-narrow {
    height: 400px;
  }
  .rc-height-standard {
    height: 500px;
  }
  .rc-height-tall {
    height: 600px;
  }
  .rc-heading-one {
    font-size: 38px;
  }
  .rc-heading-two {
    font-size: 38px;
  }
}
@media (min-width: 1280px) {
  .rc-overlay {
    top: 40%;
  }
  .rc-height-narrow {
    height: 600px;
  }
  .rc-height-standard {
    height: 700px;
  }
  .rc-height-tall {
    height: 800px;
  }
  .rc-heading-one {
    font-size: 70px;
  }
  .rc-heading-two {
    font-size: 70px;
  }
}
a:hover span,
a:focus span {
  color: white !important; /* ayko style overide */
}
</style>
